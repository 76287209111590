import * as React from 'react';
import { createClient } from 'contentful'
import useSWR from 'swr'
import { Layout } from '../components'

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;

const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string

let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;
if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 

let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;
if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

const fetcher = async () => {
    const entryItems = await client.getEntries({ content_type: 'page' })
    return entryItems.items[0].fields
}

const Pages=  () => {
    const { data } = useSWR('contentful', fetcher)
    if(data) {
        return (
            <Layout data={data} />
        );
    }
    return null;
}

export default Pages;