import * as React from 'react';
import { createClient } from 'contentful'
import useSWR from 'swr'
import _ from 'lodash'

import { ReactComponent as SearchIcon }from '../assets/icons/search-icon.svg'
import { ReactComponent as CloseIcon }from '../assets/icons/close-icon.svg'

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;

const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string

let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;

if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 

let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;
if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

interface HeaderProps {
    header: any;
}

const Header: React.FC<HeaderProps> = ({ header }) => {

    const [headerData, setHeaderData] = React.useState(false);
    const inputRef = React.useRef() as any;
    const searchRef = React.useRef() as any;

    const showForm = () =>{
        searchRef.current.classList.toggle("show-search");
        inputRef.current.focus();
    }

    const searchQuery = (e: any) =>{
        // console.log(e);
        if(e.key === "Enter") {
            window.location.href="https://www.sky.co.nz/results?q="+e.target.value
        }
    }

    const headerFetcher = async (header: any) => {
        const entryItems = [] as any
        await header.map(async (entry: any, index: number) =>{
            const entryResult = await client.getEntry(entry.sys.id)
            entryItems.push({order: index, menu_items: entryResult.fields})
            if(entryItems.length === header.length) {
                setHeaderData(true)
            }
        })
        return { entryItems }
    }
    
    const { data } = useSWR('header', ()=> headerFetcher(header.menuSections));

    if(headerData === true) {
        const subSections = _.orderBy(data?.entryItems, ['order'], ['asc'])
        return (
            <section className="header fixed z-[100] top-0 w-screen bg-white h-[50px] lg:h-[70px]">
                <div className="wrapper max-w-[2000px] mx-auto">
                    <div className="absolute w-[300px] right-[35px] bottom-[19px] opacity-0 -z-20 transition-all" ref={searchRef}>
                        <SearchIcon className="absolute left-0 bottom-[4px]"/>
                        <CloseIcon className="absolute right-0 bottom-[4px] cursor-pointer"  onClick={()=>showForm()}/>
                        <input className="border-b border-solid border-dark-grey w-full h-[40px] text-[18px] font-sky-book px-[40px] outline-none" type="text" name="query" ref={inputRef} onKeyPress={(e)=>searchQuery(e)}/>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-12 px-[10px] py-[10px] lg:py-[18px] lg:px-[20px]">
                        {subSections.map((list: any, listIndex: number)=>{
                            let menuCols ="";
                            switch(listIndex) {
                                case 0:
                                    menuCols="col-span-1"
                                break;
                                case 1:
                                    menuCols="hidden lg:inline lg:col-span-9"
                                break;
                                case 2:
                                    menuCols="col-span-1 lg:col-span-2 justify-self-end"
                                break;
                                default:
                                    menuCols="col";
                            }
                            return (
                                <div className={menuCols} key={listIndex}>
                                    <ul className="">
                                    {list.menu_items.sectionLinks.map((item: any, itemIndex: number)=>{
                                        const menuState = listIndex === 0 &&  itemIndex === 0 ? "inline-block lg:hidden" : "inline-block";
                                        if(item.fields.itemUrl === "/search") {
                                            return (
                                                <li className={item.fields.itemIcon?.fields.file.url ?  "align-middle px-[6px] lg:px-[10px] xl:px-[14px] "+menuState : "align-middle pt-[7px] px-[6px] lg:px-[10px] xl:px-[14px] "+menuState} key={itemIndex}>
                                                    <div className="absolute w-[42px] h-[42px] z-1 cursor-pointer" onClick={()=>showForm()}></div>
                                                    <img className="h-[25px] lg:h-[36px]" src={item.fields.itemIcon?.fields.file.url} alt={item.fields.name} />
                                                </li>
                                            )
                                        }
                                        else {
                                            return (
                                                <li className={item.fields.itemIcon?.fields.file.url ?  "align-middle px-[6px] lg:px-[10px] xl:px-[14px] "+menuState : "align-middle pt-[7px] px-[6px] lg:px-[10px] xl:px-[14px] "+menuState} key={itemIndex}>
                                                    <a className="font-sky-bold text-[18px] cursor-pointer" href={item.fields.itemUrl ? item.fields.itemUrl : "/"}>
                                                        {item.fields.itemIcon?.fields.file.url ? 
                                                        <img className="h-[25px] lg:h-[36px]" src={item.fields.itemIcon?.fields.file.url} alt={item.fields.name}/>
                                                        :item.fields.name}</a>
                                                </li>
                                            )
                                        }
                                    })}
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
    return null;
}

export default Header;