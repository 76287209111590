import { createClient } from "contentful";

const ACCOUNT_API = process.env.REACT_APP_ENDPOINT!;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_TVGUIDE!;
const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
const LIFERAY_CONNECT_ENDPOINT = process.env.REACT_APP_LIFERAY_CONNECT_ENDPOINT!;
const REACT_APP_ROI_CONNECT_ENDPOINT = process.env.REACT_APP_ROI_CONNECT_ENDPOINT!;
const DOMAIN = process.env.REACT_APP_DOMAIN!;
const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
const HORIZONTAL_VIDEO_PLAYER_URL =
  process.env.HORIZONTAL_VIDEO_PLAYER_URL ??
  'https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=';
const SPLIT_KEY = 'skyweb';
const KONAKART_ENDPOINT = process.env.REACT_APP_KONAKART_ENDPOINT!;
const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
const APP_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!;
// Sentry deny urls as arrays of RegExps
const SENTRY_DENY_URLS = (process.env.REACT_APP_SENTRY_DENY_URLS || '')
  .split(',')
  .filter(s => !!s)
  .map(s => new RegExp(s, 'i'));

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string
let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;

if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 
let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;

if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

export {
  ACCOUNT_API,
  SENTRY_DSN,
  NAVIGATION_URL,
  AUTH0_LOGOUT_URL,
  LIFERAY_CONNECT_ENDPOINT,
  REACT_APP_ROI_CONNECT_ENDPOINT,
  DOMAIN,
  SPLIT_AUTHORIZATION_KEY,
  SPLIT_KEY,
  KONAKART_ENDPOINT,
  ACCOUNT_URL,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
  HORIZONTAL_VIDEO_PLAYER_URL,
  client,
};