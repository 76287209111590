import React from 'react';
import { CarouselContainer } from '../scrollingBanner/CarouselContainer';
import { BannerContent, WebContentHomePageBanner } from './WebContentHomePageBanner';
import { createClient } from 'contentful'
import useSWR from 'swr'

interface rollingBanner {
  bannerContent?: BannerContent[];
  sendBanner?:any;
}
interface skyHomeBanner {
  data?: any;
}

const slickSettings = {
  adaptiveHeight: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customClassName: 'more-choice-for-you-section-carousel more-choice-for-you-section-carousel-slick-dots mb-12',
};


export const SkyHomeBannerCarousel = ({ bannerContent , sendBanner}: rollingBanner) => {
const bannerFields = bannerContent?.map((bannerItem:any)=>{ 
    return bannerItem?.fields
  })

//for arrows 
const DartPrevArrow = () => {
  return (
    <div className={'w-12 h-12 z-100 block'}>  
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <circle cx="14" cy="14" r="14" transform="matrix(-1 0 0 1 28 0)" fill="#00013A"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7071 19.7071C17.0976 19.3166 17.0976 18.6834 16.7071 18.2929L12.4142 14L16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289C16.3166 7.90237 15.6834 7.90237 15.2929 8.29289L10.2929 13.2929C9.90237 13.6834 9.90237 14.3166 10.2929 14.7071L15.2929 19.7071C15.6834 20.0976 16.3166 20.0976 16.7071 19.7071Z" fill="white"/>
      </svg>
    </div>
  );
};

const DartNextArrow = () => {
  return (
    <div className={'w-12 h-12 z-100 block'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <circle cx="14" cy="14" r="14" fill="#00013A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L15.5858 14L11.2929 9.70711C10.9024 9.31658 10.9024 8.68342 11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L17.7071 13.2929C18.0976 13.6834 18.0976 14.3166 17.7071 14.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071Z" fill="white"/>
        </svg>
    </div>
  );
};
  return (
    <>
 
    <div className="overflow-hidden slick-slide-mob">
      <CarouselContainer
        {...{
          ...slickSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          infinite: true,
          speed: 500,
          // speed: 250,
          prevArrow: (
            <div>
              <button className={'relative'} style={{right: '-3rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8673 28.1531C24.4252 27.5952 24.4252 26.6907 23.8673 26.1328L17.7346 20.0001L23.8673 13.8674C24.4252 13.3095 24.4252 12.405 23.8673 11.8471C23.3094 11.2892 22.4049 11.2892 21.847 11.8471L14.7041 18.99C14.1462 19.5478 14.1462 20.4524 14.7041 21.0103L21.847 28.1531C22.4049 28.711 23.3094 28.711 23.8673 28.1531Z" fill="#0057FF"/>
                </svg>
              </button>
             </div>                                    
          ),
          nextArrow: (            
              <div>     
                  <button className={'relative'} style={{left: '-2.5rem' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <circle cx="20" cy="20" r="20" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1328 28.1531C15.5749 27.5952 15.5749 26.6907 16.1328 26.1328L22.2655 20.0001L16.1328 13.8674C15.5749 13.3095 15.5749 12.405 16.1328 11.8471C16.6907 11.2892 17.5952 11.2892 18.1531 11.8471L25.2959 18.99C25.8538 19.5478 25.8538 20.4524 25.2959 21.0103L18.1531 28.1531C17.5952 28.711 16.6907 28.711 16.1328 28.1531Z" fill="#0057FF"/>
                    </svg>
                  </button>
                 </div>        
          ),
          responsiveBlock: [
            {
              breakpoint: 750,
              settings: {
                ...slickSettings,
                dots: true,
                arrows: true,
                infinite: true,
                slidesToShow: 1,
                swipeToSlide: true,
                centerMode: true,
                centerPadding: '2px',
                prevArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '23.5rem', right: '-9rem' }}>
                      <DartPrevArrow />
                    </button>
                  </div>
                ),
                nextArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '23.5rem', left: '-8rem' }}>
                      <DartNextArrow />
                    </button>
                  </div>
                ),
              },
            },
            {
              breakpoint: 1024,
              settings: {
                ...slickSettings,
                dots: true,
                arrows: true,
                infinite: true,
                swipeToSlide: true,
                slidesToShow: 1,
                prevArrow: (
                  <div>
                    <button className={'relative'} style={{ top: '18rem', right: '-22rem' }}>
                      <DartPrevArrow />
                    </button>
                  </div>
                ),
                nextArrow: (
                  <div>                 
                    <button className={'relative'} style={{ top: '18rem', left: '-21rem' }}>
                      <DartNextArrow />
                    </button>
                  </div>
                ),
              },
            },
          ],
        }}>
        {bannerFields?.map((rollingBanner: any, i: number) => {
          return (
            <div key={i}>
              <WebContentHomePageBanner bannerContent={rollingBanner}  sendBanner={sendBanner}/>
            </div>
          );
        })}
      </CarouselContainer>
    </div>
    </>
  );
};