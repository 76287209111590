import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GenericProductCard } from '@sky-tv-group/components/dist/components/GenericProductCard';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '../../../assets/icons/icon_right_arrow.svg';
import { ReactComponent as PrevIcon } from '../../../assets/icons/icon_left_arrow.svg';

interface CardPanelProps {
  module: any;
  sendProduct: any;
}

const CardPanel: React.FC<CardPanelProps> = ({ module, sendProduct }) => {
  React.useEffect(() => {
    const mobile = document.querySelectorAll('.scroll-on-mobile');
    mobile.forEach((item) => {
      item.scrollLeft = 300;
    });
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          right: -35,
          top: 260,
        }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          left: -80,
          top: 260,
        }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }

  return (
    <section className="section-card-panel py-[40px] pl-[20px] pr-[0px] lg:px-[30px] mx-auto max-w-[1280px]">
      {/* {module.productCategory ? (
        <h5 className="mb-[10px] text-[14px]">{module.productCategory}</h5>
      ) : null} */}
      <div className="lg:grid lg:grid-cols-12 mb-[50px] -lg:mb-[10px] lg:mb-[20px] relative">
        <div
          className={
            module.cardPanelHeadingGradient +
            ' lg:w-[600px] mb-[10px] col-span-4 text-[30px] tracking-[-1px]'
          }
        >
          {module.productCategory ? module.productCategory+ " ":""}{documentToReactComponents(module.cardPanelHeading)}
        </div>
      </div>
      <div className="product-slider slider max-w-[1080px] mx-auto">
        <Slider {...settings}>
          {module.cardsToShow.map((card: any, index: number) => {
            const cardData = card.fields;
            return (
              <div
                className="slide px-sky-sm flex h-full py-sky-lg pl-0"
                key={index}
              >
                <GenericProductCard
                  kkService={null as any}
                  secondaryProductCardCTA={()=>sendProduct("Product Added", "acquisition", "DTH", cardData.title, cardData.title, cardData.title, cardData.price, 1, cardData.sku, cardData.type[0])}
                  card={{
                    addLink:cardData.addLink,
                    bgColor: cardData.bgColor ? cardData.bgColor : '#000000',
                    billingFrequency: cardData.priceFrequencyType,
                    imageSrc: {
                      text: cardData.thumbnail?.fields?.file?.text ?? '',
                      url: cardData.thumbnail?.fields?.file?.url ?? '',
                    },
                    lozengeText: cardData.lozengeText,
                    sku: cardData.sku,
                    subtitle: cardData.description,
                    discountedPrice: cardData.price ? cardData.price : null,
                    title: cardData.title,
                    type: cardData.type,
                    coupon: null,
                    termsAndConditions: cardData.termsAndConditionsCaption,
                    descriptionList: cardData.detailsList,
                    copyrightText: cardData.copyrightText,
                    ctaName: cardData.ctaName,
                    iconList: cardData.iconList?.map((icon: any) => ({
                      url: icon?.fields?.file?.url ?? '',
                      text: icon?.fields?.file?.text ?? '',
                    })),
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="text-[12px] mb-[20px] lg:mb-[64px] mt-[40px] lg:mt-[50px] w-full max-w-[85vw]">
        {documentToReactComponents(module.termsAndConditions)}
      </div>
    </section>
  );
};

export default CardPanel;
