import * as React from 'react';
import { createClient } from 'contentful'
import useSWR from 'swr'
import _ from 'lodash'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactComponent as SkyPurplePink } from '../assets/footer-backgrounds/sky-purple-pink.svg'
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down-icon.svg'

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;

const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string

let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;
if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 

let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;
if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

interface FooterProps {
    footer: any;
}

const Footer: React.FC<FooterProps> = ({ footer }) => {

    const [footerData, setfooterData] = React.useState(false);

    const footerFetcher = async (footer: any) => {
        const entryItems = [] as any
        await footer.map(async (entry: any, index: number) =>{
            const entryResult = await client.getEntry(entry.sys.id)
            entryItems.push({order: index, menu_items: entryResult.fields})
            if(entryItems.length === footer.length) {
                setfooterData(true)
            }
        })
        return { entryItems }
    }
    
    const { data } = useSWR('footer', ()=> footerFetcher(footer.menuSections));

    if(footerData === true) {
        const subSections = _.orderBy(data?.entryItems, ['order'], ['asc'])
        return (
            <section className="section-footer pink-orange relative py-[20px] px-[20px] lg:p-0 lg:selection:h-[248px] lg:overflow-hidden max-w-[2000px] mx-auto">
                <div className="lg:grid lg:grid-cols-12 lg:pr-[3%]">
                    <div className="hidden lg:col-span-2 lg:inline">
                        <SkyPurplePink className="absolute z-3"/>
                    </div>
                    <div className="lg:col-span-10 lg:pt-[46px] relative">
                        <div className="lg:grid lg:grid-cols-12 lg:h-[202px]">
                            {subSections.map((list: any, listIndex: number)=>{
                                if(listIndex <4) {
                                    let columnSet = "";
                                    let socialSet = "";
                                    switch(listIndex) {
                                        case 0:
                                            columnSet = "lg:col-span-2"
                                        break;
                                        case 1:
                                            columnSet = "lg:col-span-3"
                                        break;
                                        case 2:
                                            columnSet = "lg:col-span-2 "
                                        break;
                                        case 3:
                                            columnSet = "lg:col-span-5 lg:justify-self-end"
                                            socialSet = "inline-block my-[20px] justify-center lg:m-0 md:pr-[20px] align-center lg:align-left"
                                        break;
                                        default:
                                    }
                                    return (
                                        <div className={columnSet + " relative"} key={listIndex}>
                                            {listIndex < 3 ?
                                            <>
                                            <ChevronDown className="inline absolute top-[10px] right-[10px] lg:hidden origin-center -z-1" />
                                            <h6 onClick={(e:any)=>{
                                                    e.target.parentNode.childNodes[2].classList.toggle("hidden")
                                                    e.target.parentNode.childNodes[0].classList.toggle("rotate-180")
                                                }} 
                                                className="cursor-pointer border-b border-white-50 pb-[20px] lg:pb-0 lg:border-none text-white text-[18px] my-[20px] lg:my-0 lg:text-[14px] lg:leading-[21px] relative">{list.menu_items.sectionName}
                                                
                                            </h6>
                                            </>
                                            : null }
                                            <ul className={listIndex===3 ? "flex justify-between md:inline" : "hidden : lg:inline"}>
                                            {list.menu_items.sectionLinks.map((item: any, itemIndex: number)=>{
                                                return (
                                                    <li className={"whitespace-nowrap "+socialSet} key={itemIndex}>
                                                        <a className="text-white text-[14px] leading-[20px] pr-[10px]" href={item.fields.itemUrl ? item.fields.itemUrl : "/"}>
                                                            {item.fields.itemIcon?.fields.file.url ? 
                                                            <img src={item.fields.itemIcon?.fields.file.url} alt={item.fields.name}/>
                                                            :item.fields.name}</a>
                                                    </li>
                                                )
                                            })}
                                            </ul>
                                        </div>
                                    )
                                }
                                return null
                            })} 
                        </div>
                        <div className="md:grid md:grid-cols-12 border-t border-white-50 lg:absolute bottom-0 w-full pb-[5px] pt-[8px] ">
                            <div className="hidden lg:inline lg:col-span-6 text-white text-[12px] leading-[24px]">
                                {documentToReactComponents(footer.footerCopyright)}
                            </div>
                            {subSections.map((list: any, listIndex: number)=>{
                                if(listIndex === 4) {
                                    return (
                                        <div className={"text-center md:col-span-6 md:justify-self-end"} key={listIndex}>
                                            <ul>
                                            {list.menu_items.sectionLinks.map((item: any, itemIndex: number)=>{
                                                return (
                                                    <li className="px-[5px] whitespace-nowrap inline-block md:pl-[20px] " key={itemIndex}>
                                                        <a className="text-white text-[12px] leading-[20px]" href={item.fields.itemUrl ? item.fields.itemUrl : "/"}>
                                                            {item.fields.itemIcon?.fields.file.url ? 
                                                            <img src={item.fields.itemIcon?.fields.file.url} alt={item.fields.name}/>
                                                            :item.fields.name}</a>
                                                    </li>
                                                )
                                            })}
                                            </ul>
                                        </div>
                                    )
                                }
                                return null
                            })}
                            <div className="inline text-center lg:hidden md:col-span-6 text-white text-[12px] leading-[24px]">
                                {documentToReactComponents(footer.footerCopyright)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    return null;
}

export default Footer;